export * from './attributes.service';
import { AttributesService } from './attributes.service';
export * from './calendars.service';
import { CalendarsService } from './calendars.service';
export * from './commands.service';
import { CommandsService } from './commands.service';
export * from './devices.service';
import { DevicesService } from './devices.service';
export * from './drivers.service';
import { DriversService } from './drivers.service';
export * from './events.service';
import { EventsService } from './events.service';
export * from './geofences.service';
import { GeofencesService } from './geofences.service';
export * from './groups.service';
import { GroupsService } from './groups.service';
export * from './maintenance.service';
import { MaintenanceService } from './maintenance.service';
export * from './notifications.service';
import { NotificationsService } from './notifications.service';
export * from './permissions.service';
import { PermissionsService } from './permissions.service';
export * from './positions.service';
import { PositionsService } from './positions.service';
export * from './reports.service';
import { ReportsService } from './reports.service';
export * from './server.service';
import { ServerService } from './server.service';
export * from './session.service';
import { SessionService } from './session.service';
export * from './statistics.service';
import { StatisticsService } from './statistics.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [
    AttributesService,
    CalendarsService,
    CommandsService,
    DevicesService,
    DriversService,
    EventsService,
    GeofencesService,
    GroupsService,
    MaintenanceService,
    NotificationsService,
    PermissionsService,
    PositionsService,
    ReportsService,
    ServerService,
    SessionService,
    StatisticsService,
    UsersService];
