/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Device } from '../model/device';
import { DeviceAccumulators } from '../model/deviceAccumulators';

// import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
// import { Configuration }                                     from '../configuration';

import { environment } from 'src/environments/environment';


@Injectable()
export class DevicesService {

    public defaultHeaders = new HttpHeaders();
    //public configuration = new Configuration();
    //basePath = localStorage.getItem('servidor') + '/api';
    public basePath = environment.serverURL;

    constructor(
        protected httpClient: HttpClient,
        // @Optional()@Inject(BASE_PATH) basePath: string,
        // @Optional() configuration: Configuration
        ) {
        // if (basePath) {
        //     this.basePath = basePath;
        // }
        // if (configuration) {
        //     this.configuration = configuration;
        //     this.basePath = basePath || configuration.basePath || this.basePath;
        // }
    }


    /*
     * Fetch a list of Devices
     * Without any params, returns a list of the user&#x27;s devices
     * @param all Can only be used by admins or managers to fetch all entities
     * @param userId Standard users can use this only with their own _userId_
     * @param id To fetch one or more devices. Multiple params can be passed like &#x60;id&#x3D;31&amp;id&#x3D;42&#x60;
     * @param uniqueId To fetch one or more devices.
     * Multiple params can be passed like &#x60;uniqueId&#x3D;333331&amp;uniqieId&#x3D;44442&#x60;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public devicesGet(
        all?: boolean,
        userId?: number,
        id?: number,
        uniqueId?: string,
        observe?: 'body',
        reportProgress?: boolean): Observable<Array<Device>>;
    public devicesGet(
        all?: boolean, userId?: number,
        id?: number, uniqueId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Device>>>;
    public devicesGet(
        all?: boolean, userId?: number,
        id?: number, uniqueId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Device>>>;
    public devicesGet(
        all?: boolean, userId?: number,
        id?: number, uniqueId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (all !== undefined && all !== null) {
            queryParameters = queryParameters.set('all', <any>all);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (uniqueId !== undefined && uniqueId !== null) {
            queryParameters = queryParameters.set('uniqueId', <any>uniqueId);
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        // if (this.configuration.username || this.configuration.password) {
        if (localStorage.getItem('username') || localStorage.getItem('password')) {
            // headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
            // headers = headers.set('Authorization', 'Basic ' + localStorage.getItem('token'));
            headers = headers.set(
                'Authorization', 'Basic ' + btoa(localStorage.getItem('username') + ':' + localStorage.getItem('password'))
            );
        }

        // to determine the Accept header
        // const httpHeaderAccepts: string[] = [
        //     'application/json'
        // ];
        // const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        // if (httpHeaderAcceptSelected !== undefined) {
        //     headers = headers.set('Accept', httpHeaderAcceptSelected);
        // }
        headers = headers.set('Accept', 'application/json');

        // to determine the Content-Type header
        // const consumes: string[] = [
        // ];

        return this.httpClient.request<Array<Device>>('get',`${this.basePath}/devices`,
            {
                params: queryParameters,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Update total distance and hours of the Device
     *
     * @param body
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public devicesIdAccumulatorsPut(
        body: DeviceAccumulators, id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public devicesIdAccumulatorsPut(
        body: DeviceAccumulators, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public devicesIdAccumulatorsPut(
        body: DeviceAccumulators, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public devicesIdAccumulatorsPut(
        body: DeviceAccumulators, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling devicesIdAccumulatorsPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling devicesIdAccumulatorsPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        // if (this.configuration.username || this.configuration.password) {
            // headers = headers.set('Authorization', 'Basic ' + localStorage.getItem('token'));
        //     headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        // }
        headers = headers.set('Authorization', 'Basic ' + btoa(localStorage.getItem('username') + ':' + localStorage.getItem('password')));

        // to determine the Accept header
        // const httpHeaderAccepts: string[] = [
        // ];
        // const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        // if (httpHeaderAcceptSelected !== undefined) {
        //     headers = headers.set('Accept', httpHeaderAcceptSelected);
        // }
        // headers = headers.set('Accept', httpHeaderAcceptSelected);

        // to determine the Content-Type header
        // const consumes: string[] = [
        //     'application/json'
        // ];
        // const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        // if (httpContentTypeSelected !== undefined) {
        //     headers = headers.set('Content-Type', httpContentTypeSelected);
        // }
        headers = headers.set('Content-Type', 'application/json');

        return this.httpClient.request<any>('put',`${this.basePath}/devices/${encodeURIComponent(String(id))}/accumulators`,
            {
                body,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Delete a Device
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public devicesIdDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public devicesIdDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public devicesIdDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public devicesIdDelete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling devicesIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        // if (this.configuration.username || this.configuration.password) {
        //     headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        // }
        headers = headers.set('Authorization', 'Basic ' + btoa(localStorage.getItem('username') + ':' + localStorage.getItem('password')));

        // to determine the Accept header
        // const httpHeaderAccepts: string[] = [
        // ];
        // const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        // if (httpHeaderAcceptSelected !== undefined) {
        //     headers = headers.set('Accept', httpHeaderAcceptSelected);
        // }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/devices/${encodeURIComponent(String(id))}`,
            {
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Update a Device
     *
     * @param body
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public devicesIdPut(body: Device, id: number, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public devicesIdPut(body: Device, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public devicesIdPut(body: Device, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public devicesIdPut(body: Device, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling devicesIdPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling devicesIdPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        // if (this.configuration.username || this.configuration.password) {
        //     headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        // }
        headers = headers.set('Authorization', 'Basic ' + btoa(localStorage.getItem('username') + ':' + localStorage.getItem('password')));

        // to determine the Accept header
        // const httpHeaderAccepts: string[] = [
        //     'application/json'
        // ];
        // const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        // if (httpHeaderAcceptSelected !== undefined) {
        //     headers = headers.set('Accept', httpHeaderAcceptSelected);
        // }
        headers = headers.set('Accept', 'application/json');

        // to determine the Content-Type header
        // const consumes: string[] = [
        //     'application/json'
        // ];
        // const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        // if (httpContentTypeSelected !== undefined) {
        //     headers = headers.set('Content-Type', httpContentTypeSelected);
        // }
        headers = headers.set('Content-Type', 'application/json');

        return this.httpClient.request<Device>('put',`${this.basePath}/devices/${encodeURIComponent(String(id))}`,
            {
                body,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Create a Device
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public devicesPost(body: Device, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public devicesPost(body: Device, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public devicesPost(body: Device, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public devicesPost(body: Device, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling devicesPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        // if (this.configuration.username || this.configuration.password) {
        //     headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        // }
        headers = headers.set('Authorization', 'Basic ' + btoa(localStorage.getItem('username') + ':' + localStorage.getItem('password')));

        // to determine the Accept header
        // const httpHeaderAccepts: string[] = [
        //     'application/json'
        // ];
        // const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        // if (httpHeaderAcceptSelected !== undefined) {
        //     headers = headers.set('Accept', httpHeaderAcceptSelected);
        // }
        headers = headers.set('Accept', 'application/json');

        // to determine the Content-Type header
        // const consumes: string[] = [
        //     'application/json'
        // ];
        // const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        // if (httpContentTypeSelected !== undefined) {
        //     headers = headers.set('Content-Type', httpContentTypeSelected);
        // }
        headers = headers.set('Content-Type', 'application/json');

        return this.httpClient.request<Device>('post',`${this.basePath}/devices`,
            {
                body,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

}
