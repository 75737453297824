/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Maintenance } from '../model/maintenance';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

import { environment } from 'src/environments/environment';

@Injectable()
export class MaintenanceService {

    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    protected basePath = environment.serverURL;

    constructor(
        protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }


    /*
     * Fetch a list of Maintenance
     * Without params, it returns a list of Maintenance the user has access to
     * @param all Can only be used by admins or managers to fetch all entities
     * @param userId Standard users can use this only with their own _userId_
     * @param deviceId Standard users can use this only with _deviceId_s, they have access to
     * @param groupId Standard users can use this only with _groupId_s, they have access to
     * @param refresh
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public maintenanceGet(
        all?: boolean, userId?: number, deviceId?: number,
        groupId?: number, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<Maintenance>>;
    public maintenanceGet(
        all?: boolean, userId?: number, deviceId?: number,
        groupId?: number, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Maintenance>>>;
    public maintenanceGet(
        all?: boolean, userId?: number, deviceId?: number,
        groupId?: number, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Maintenance>>>;
    public maintenanceGet(
        all?: boolean, userId?: number, deviceId?: number,
        groupId?: number, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (all !== undefined && all !== null) {
            queryParameters = queryParameters.set('all', <any>all);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('deviceId', <any>deviceId);
        }
        if (groupId !== undefined && groupId !== null) {
            queryParameters = queryParameters.set('groupId', <any>groupId);
        }
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Maintenance>>('get',`${this.basePath}/maintenance`,
            {
                params: queryParameters,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Delete a Maintenance
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public maintenanceIdDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public maintenanceIdDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public maintenanceIdDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public maintenanceIdDelete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling maintenanceIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/maintenance/${encodeURIComponent(String(id))}`,
            {
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Update a Maintenance
     *
     * @param body
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public maintenanceIdPut(
        body: Maintenance, id: number, observe?: 'body', reportProgress?: boolean): Observable<Maintenance>;
    public maintenanceIdPut(
        body: Maintenance, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Maintenance>>;
    public maintenanceIdPut(
        body: Maintenance, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Maintenance>>;
    public maintenanceIdPut(
        body: Maintenance, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling maintenanceIdPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling maintenanceIdPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Maintenance>('put',`${this.basePath}/maintenance/${encodeURIComponent(String(id))}`,
            {
                body,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Create a Maintenance
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public maintenancePost(body: Maintenance, observe?: 'body', reportProgress?: boolean): Observable<Maintenance>;
    public maintenancePost(body: Maintenance, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Maintenance>>;
    public maintenancePost(body: Maintenance, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Maintenance>>;
    public maintenancePost(body: Maintenance, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling maintenancePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Maintenance>('post',`${this.basePath}/maintenance`,
            {
                body,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

}
