/*
 * Traccar
 * Traccar GPS tracking server API documentation. To use the API you need to have a server instance.
 * For testing purposes you can use one of free [demo servers](https://www.traccar.org/demo-server/).
 * For production use you can install your own server or get a [subscription service](https://www.traccar.org/product/tracking-server/).
 *
 * OpenAPI spec version: 5.1
 * Contact: support@traccar.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface User {
    id?: number;
    name?: string;
    email?: string;
    phone?: string;
    readonly?: boolean;
    administrator?: boolean;
    map?: string;
    latitude?: number;
    longitude?: number;
    zoom?: number;
    password?: string;
    twelveHourFormat?: boolean;
    coordinateFormat?: string;
    disabled?: boolean;
    /**
     * in IS0 8601 format. eg. `1963-11-22T18:30:00Z`
     */
    expirationTime?: Date;
    deviceLimit?: number;
    userLimit?: number;
    deviceReadonly?: boolean;
    limitCommands?: boolean;
    poiLayer?: string;
    token?: string;
    attributes?: any;
}
