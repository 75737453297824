/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Command } from '../model/command';
import { CommandType } from '../model/commandType';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

import { environment } from 'src/environments/environment';

@Injectable()
export class CommandsService {

    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    protected basePath = environment.serverURL;

    constructor(
        protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /*
     * Fetch a list of Saved Commands
     * Without params, it returns a list of Saved Commands the user has access to
     * @param all Can only be used by admins or managers to fetch all entities
     * @param userId Standard users can use this only with their own _userId_
     * @param deviceId Standard users can use this only with _deviceId_s, they have access to
     * @param groupId Standard users can use this only with _groupId_s, they have access to
     * @param refresh
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commandsGet(
        all?: boolean, observe?: 'body', deviceId?: number, userId?: number, groupId?: number,
        refresh?: boolean, reportProgress?: boolean): Observable<Array<Command>>;
    public commandsGet(
        all?: boolean, observe?: 'response', deviceId?: number, userId?: number, groupId?: number,
        refresh?: boolean, reportProgress?: boolean): Observable<HttpResponse<Array<Command>>>;
    public commandsGet(
        all?: boolean, observe?: 'events', deviceId?: number, userId?: number, groupId?: number,
        refresh?: boolean, reportProgress?: boolean): Observable<HttpEvent<Array<Command>>>;
    public commandsGet(
        all?: boolean, observe: any = 'body', deviceId?: number, userId?: number, groupId?: number,
        refresh?: boolean, reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (all !== undefined && all !== null) {
            queryParameters = queryParameters.set('all', <any>all);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('deviceId', <any>deviceId);
        }
        if (groupId !== undefined && groupId !== null) {
            queryParameters = queryParameters.set('groupId', <any>groupId);
        }
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Command>>('get',`${this.basePath}/commands`,
            {
                params: queryParameters,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Delete a Saved Command
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commandsIdDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commandsIdDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commandsIdDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commandsIdDelete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commandsIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/commands/${encodeURIComponent(String(id))}`,
            {
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Update a Saved Command
     *
     * @param body
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commandsIdPut(body: Command, id: number, observe?: 'body', reportProgress?: boolean): Observable<Command>;
    public commandsIdPut(body: Command, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Command>>;
    public commandsIdPut(body: Command, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Command>>;
    public commandsIdPut(body: Command, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling commandsIdPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling commandsIdPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Command>('put',`${this.basePath}/commands/${encodeURIComponent(String(id))}`,
            {
                body,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Create a Saved Command
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commandsPost(body: Command, observe?: 'body', reportProgress?: boolean): Observable<Command>;
    public commandsPost(body: Command, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Command>>;
    public commandsPost(body: Command, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Command>>;
    public commandsPost(body: Command, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling commandsPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Command>('post',`${this.basePath}/commands`,
            {
                body,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Fetch a list of Saved Commands supported by Device at the moment
     * Return a list of saved commands linked to Device and its groups, filtered by current Device protocol support
     * @param deviceId Standard users can use this only with _deviceId_s, they have access to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commandsSendGet(deviceId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Command>>;
    public commandsSendGet(deviceId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Command>>>;
    public commandsSendGet(deviceId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Command>>>;
    public commandsSendGet(deviceId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('deviceId', <any>deviceId);
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Command>>('get',`${this.basePath}/commands/send`,
            {
                params: queryParameters,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Dispatch commands to device
     * Dispatch a new command or Saved Command if _body.id_ set
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commandsSendPost(body: Command, observe?: 'body', reportProgress?: boolean): Observable<Command>;
    public commandsSendPost(body: Command, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Command>>;
    public commandsSendPost(body: Command, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Command>>;
    public commandsSendPost(body: Command, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling commandsSendPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Command>('post',`${this.basePath}/commands/send`,
            {
                body,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Fetch a list of available Commands for the Device or all possible Commands if Device ommited
     *
     * @param deviceId Internal device identifier. Only works if device has already reported some locations
     * @param protocol Protocol name. Can be used instead of device id
     * @param textChannel When &#x60;true&#x60; return SMS commands. If not specified or &#x60;false&#x60; return data commands
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commandsTypesGet(
        deviceId?: number, protocol?: string,
        textChannel?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<CommandType>>;
    public commandsTypesGet(
        deviceId?: number, protocol?: string,
        textChannel?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CommandType>>>;
    public commandsTypesGet(
        deviceId?: number, protocol?: string,
        textChannel?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CommandType>>>;
    public commandsTypesGet(
        deviceId?: number, protocol?: string,
        textChannel?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('deviceId', <any>deviceId);
        }
        if (protocol !== undefined && protocol !== null) {
            queryParameters = queryParameters.set('protocol', <any>protocol);
        }
        if (textChannel !== undefined && textChannel !== null) {
            queryParameters = queryParameters.set('textChannel', <any>textChannel);
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CommandType>>('get',`${this.basePath}/commands/types`,
            {
                params: queryParameters,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

}
