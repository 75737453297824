/**
 * Traccar
 * Traccar GPS tracking server API documentation.
 * To use the API you need to have a server instance.
 * For testing purposes you can use one of free [demo servers](https://www.traccar.org/demo-server/).
 * For production use you can install your own server or get a [subscription service](https://www.traccar.org/product/tracking-server/).
 *
 * OpenAPI spec version: 5.1
 * Contact: support@traccar.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Statistics {
    /**
     * in IS0 8601 format. eg. `1963-11-22T18:30:00Z`
     */
    captureTime?: Date;
    activeUsers?: number;
    activeDevices?: number;
    requests?: number;
    messagesReceived?: number;
    messagesStored?: number;
}
