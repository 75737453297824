import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {HttpClientModule, HttpClient} from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {IonicStorageModule} from '@ionic/storage-angular';
import { CookieService } from 'ngx-cookie-service';
import { Drivers } from '@ionic/storage';
import { NgxPaginationModule } from 'ngx-pagination';
// import { MaintenanceService } from './servicios/api/maintenance.service';
import {
  DevicesService,
  SessionService,
  UsersService,
  CommandsService,
  PermissionsService,
  NotificationsService,
  MaintenanceService,
  GeofencesService,
} from './servicios';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot({
      name: 'CoT',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage] // , Drivers.LocalStorage
    })
  ],
  providers: [
    CookieService,
    SessionService,
    DevicesService,
    UsersService,
    CommandsService,
    PermissionsService,
    NotificationsService,
    MaintenanceService,
    GeofencesService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
