import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./paginas/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'cot',
    loadChildren: () => import('./paginas/cot/cot.module').then( m => m.CotPageModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./paginas/usuarios/usuarios.module').then( m => m.UsuariosPageModule)
  },
  {
    path: 'reportes',
    loadChildren: () => import('./paginas/reportes/reportes.module').then( m => m.ReportesPageModule)
  },
  {
    path: 'configuraciones',
    loadChildren: () => import('./paginas/configuraciones/configuraciones.module').then( m => m.ConfiguracionesPageModule)
  },
  {
    path: 'editar-dispositivo',
    loadChildren: () => import('./paginas/editar-dispositivo/editar-dispositivo.module').then( m => m.EditarDispositivoPageModule)
  },
  {
    path: 'nuevo-dispositivo',
    loadChildren: () => import('./paginas/nuevo-dispositivo/nuevo-dispositivo.module').then( m => m.NuevoDispositivoPageModule)
  },
  {
    path: 'nuevo-usuario',
    loadChildren: () => import('./paginas/nuevo-usuario/nuevo-usuario.module').then( m => m.NuevoUsuarioPageModule)
  },
  {
    path: 'editar-usuario',
    loadChildren: () => import('./paginas/editar-usuario/editar-usuario.module').then( m => m.EditarUsuarioPageModule)
  },
  {
    path: 'stripe-modal',
    loadChildren: () => import('./paginas/stripe-modal/stripe-modal.module').then( m => m.StripeModalPageModule)
  }
  // {
  //   path: 'dispositivos',
  //   loadChildren: () => import('./paginas/dispositivos/dispositivos.module').then( m => m.DispositivosPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
