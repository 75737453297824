import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AttributesService } from './api/attributes.service';
import { CalendarsService } from './api/calendars.service';
import { CommandsService } from './api/commands.service';
import { DevicesService } from './api/devices.service';
import { DriversService } from './api/drivers.service';
import { EventsService } from './api/events.service';
import { GeofencesService } from './api/geofences.service';
import { GroupsService } from './api/groups.service';
import { MaintenanceService } from './api/maintenance.service';
import { NotificationsService } from './api/notifications.service';
import { PermissionsService } from './api/permissions.service';
import { PositionsService } from './api/positions.service';
import { ReportsService } from './api/reports.service';
import { ServerService } from './api/server.service';
import { SessionService } from './api/session.service';
import { StatisticsService } from './api/statistics.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AttributesService,
    CalendarsService,
    CommandsService,
    DevicesService,
    DriversService,
    EventsService,
    GeofencesService,
    GroupsService,
    MaintenanceService,
    NotificationsService,
    PermissionsService,
    PositionsService,
    ReportsService,
    ServerService,
    SessionService,
    StatisticsService,
    UsersService ]
})
export class ApiModule {
    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }

    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

}
