/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { User } from '../model/user';

//import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
//import { Configuration }                                     from '../configuration';

import { environment } from 'src/environments/environment';

@Injectable()
export class SessionService {

    public defaultHeaders = new HttpHeaders();
    //public configuration = new Configuration();
    public basePath = environment.serverURL;
    //basePath = localStorage.getItem('servidor') + '/api';

    constructor(
        protected httpClient: HttpClient,
        //@Optional()@Inject(BASE_PATH) basePath: string,
        //@Optional() configuration: Configuration
        ) {
        // if (basePath) {
        //     this.basePath = basePath;
        //     console.log('constructor XXX');
        // }
        // if (configuration) {
        //     console.log('constructor YYY');
        //     this.configuration = configuration;
        //     this.basePath = basePath || configuration.basePath || this.basePath;
        // }
    }


    /*
     * Close the Session
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sessionDelete(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sessionDelete(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sessionDelete(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sessionDelete(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        //console.log(localStorage.getItem('username'));
        // if (localStorage.getItem('username') || localStorage.getItem('password')) {
        if (localStorage.getItem('username') || localStorage.getItem('password')) {
            // console.log('localStorage.getItem('username')');
            // console.log(localStorage.getItem('username') );
            headers = headers.set(
                'Authorization', 'Basic ' + btoa(localStorage.getItem('username') + ':' + localStorage.getItem('password'))
            );
        }

        // to determine the Accept header
        // const httpHeaderAccepts: string[] = [
        // ];
        // const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        // if (httpHeaderAcceptSelected !== undefined) {
        //     headers = headers.set('Accept', httpHeaderAcceptSelected);
        // }
        headers = headers.set('Accept', 'application/json');

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/session`,
            {
                withCredentials: true,
                // withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Fetch Session information
     *
     * @param token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sessionGet(token?: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public sessionGet(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public sessionGet(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public sessionGet(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (localStorage.getItem('username') || localStorage.getItem('password')) {
            headers = headers.set(
                'Authorization', 'Basic ' + btoa(localStorage.getItem('username') + ':' + localStorage.getItem('password'))
            );
        }

        // to determine the Accept header
        // const httpHeaderAccepts: string[] = [
        //     'application/json'
        // ];
        // const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        // if (httpHeaderAcceptSelected !== undefined) {
        //     headers = headers.set('Accept', httpHeaderAcceptSelected);
        // }
        headers = headers.set('Accept', 'application/json');

        // to determine the Content-Type header
        // const consumes: string[] = [
        // ];

        return this.httpClient.request<User>('get',`${this.basePath}/session`,
            {
                params: queryParameters,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * Create a new Session
     *
     * @param email
     * @param password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sessionPostForm(email: string, password: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public sessionPostForm(email: string, password: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public sessionPostForm(email: string, password: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public sessionPostForm(email: string, password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling sessionPost.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling sessionPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        // if (localStorage.getItem('username') || localStorage.getItem('password')) {
        headers = headers.set(
            'Authorization', 'Basic ' + btoa(email + ':' + password)
        );
        // }

        // to determine the Accept header
        // const httpHeaderAccepts: string[] = [
        //     'application/json'
        // ];
        // const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        // if (httpHeaderAcceptSelected !== undefined) {
        //     headers = headers.set('Accept', httpHeaderAcceptSelected);
        // }
        headers = headers.set('Accept', 'application/json');

        // to determine the Content-Type header
        // const consumes: string[] = [
        //     'application/x-www-form-urlencoded'
        // ];
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');

        //const canConsumeForm = this.canConsumeForm(consumes);

        // let formParams: { append(param: string, value: any): void};
        // const useForm = false;
        // const convertFormParamsToString = false;
        // if (useForm) {
        //     formParams = new FormData();
        // } else {
        //     formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        // }

        // if (email !== undefined) {
        //     formParams = formParams.append('email', <any>email) as any || formParams;
        // }
        // if (password !== undefined) {
        //     formParams = formParams.append('password', <any>password) as any || formParams;
        // }
        //console.log(`${this.basePath}/session`);
        // console.log(`{'email':'${email}','password':'${password}'}`);
        //console.log(`email=${email}&password=${password}`);

        return this.httpClient.request<User>('post',`${this.basePath}/session`,
            {
                body: `email=${email}&password=${password}`,
                withCredentials: true,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /*
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
     private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

}
